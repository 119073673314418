var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-list-view',{attrs:{"dataSource":_vm.dataSource,"headers":_vm.headers,"loading":_vm.loading,"pageEditName":"KnowledgeBaseCourseEdit","title":"Учебные курсы","label-text-btn-create":"Создать курс","label-text-search":"Поиск курса","showCopy":"","sort-by":"Name","showCompleted":_vm.showCompleted,"notShowActions":['add']},on:{"onRefresh":_vm.init,"onDelete":_vm.del,"update:showCompleted":function($event){_vm.showCompleted=$event},"update:show-completed":function($event){_vm.showCompleted=$event}},scopedSlots:_vm._u([{key:"item.Period",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{
          'red--text':
            item.AllEmployeesCount !== item.DoneEmployeesCount &&
            _vm.moment(item.DateOut, 'DD.MM.YYYY') < _vm.moment(),
        }},[_vm._v(" "+_vm._s(item.IsLimited ? _vm.PeriodFormat(item.Period.DateIn, item.Period.DateOut) : "")+" ")])]}},{key:"item.Curators",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticStyle:{"width":"150px"}},[_vm._v(" "+_vm._s(item.Curators ? item.Curators.map(function (e) { return _vm.$options.filters.PersonShortName(e); } ).join(", ") : "")+" ")])]}},{key:"item.DoneEmployeesCount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.DoneEmployeesCount)+" из "+_vm._s(item.AllEmployeesCount)+" ("+_vm._s(_vm.completionPercentage(item))+"%) ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }